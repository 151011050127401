/* You can add global styles to this file, and also import other style files */
body {
    margin: 1.0em;
}

a {
    color: blue !important;
    cursor: pointer;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
